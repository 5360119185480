<template>
  <div>
    <TAdvancedSearch ref="TAdvancedSearch">
      <template v-slot:advanced-search>
        <Advanced
          v-if="tableSubmit"
          :searchConfiguration.sync="searchConfiguration"
          :searchObj.sync="tableSubmit"
          :tableId.sync="tableData.tabId"
        ></Advanced>
        <div></div>
      </template>
    </TAdvancedSearch>

    <KTPortlet>
      <template v-slot:toolbar>
        <div class="filter" v-if="0">
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            label="View Model"
            label-align-sm="right"
            label-size="sm"
            label-for="view_mode"
          >
            <SelectLoading
              v-if="option.view_mode == 0"
              :size="0.7"
            ></SelectLoading>
            <sv-select
              v-model="tableSubmit.view_mode"
              :placeholder="$t('page.please_choose')"
              :options="option.view_mode"
              :reduce="options => options.value"
              :clearable="false"
              @input="renewTable"
              id="view_mode"
              label="label"
            >
            </sv-select>
          </b-form-group>
        </div>
      </template>
      <template v-slot:body>
        <b-overlay :show="loading" class="head-loading">
          <div class="operate-box">
            <Export
              v-if="0"
              v-b-popover.hover.topleft=""
              :exportText="'Export Reservation'"
              :exportUrl="exportUrl"
              :exportDisabled="exportDisabled"
            ></Export>
            <MassStoreArticleReview
              v-if="0"
              :selectData="selected"
              :ids="selectIds"
              :disabled="loading"
              :initDataFn="renewTable"
            >
            </MassStoreArticleReview>
          </div>
          <CustomTable
            v-if="tableSubmit"
            :itemsFn="itemsFn"
            :tableData="tableData"
            :tableObj.sync="tableSubmit"
            :tableId.sync="tableData.tabId"
            :tableLoading="loading"
            :checkboxChange="checkboxChange"
            :checkboxAll="checkboxAll"
          ></CustomTable>
        </b-overlay>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import apiBus from '@/common/apiBus/index';
import TAdvancedSearch from '@/components/TAdvancedSearch/Index';
import KTPortlet from '@/views/content/Portlet';
import Advanced from '@/components/Search/Ordinary.vue';
import CustomTable from '@/components/Table/Index.vue';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
import SelectLoading from '@/components/Loading/Index';
import Export from '@/components/Export/Index.vue';
import MassStoreArticleReview from '@/views/content/StoreArticleReview/Index.vue';

export default {
  name: 'ArticleReview',
  components: {
    TAdvancedSearch,
    KTPortlet,
    Advanced,
    CustomTable,
    SelectLoading,
    Export,
    MassStoreArticleReview
  },
  data() {
    return {
      // 高级搜索配置
      searchConfiguration: {
        status: {
          type: 'checked-all',
          text: 'Status',
          allSelected: true,
          class: 'text_color_all',
          resetVal: [210, 220, 230],
          md: 12,
          options: [
            { label: 'Received', value: 210, class: 'text_blue' },
            { label: 'Stored', value: 220, class: 'text_green2' },
            { label: 'Rejected', value: 230, class: 'text_red' }
          ]
        },
        reception_date: {
          type: 'datepicker_from_to',
          md: '6',
          text: 'Reception Date',
          limit: {
            // start: 30,
            end: 0
          },
          from: {
            placeholder: 'From'
            // resetVal: this.format(new Date().setDate(new Date().getDate() - 30))
          },
          to: {
            placeholder: 'To'
            // resetVal: this.format(new Date())
          }
        },
        warehouse: {
          type: 'select',
          text: 'Warehouse',
          placeholder: this.$t('page.please_choose'),
          // selectable: 'disabled',
          options: []
        },
        purchase_id: {
          type: 'input',
          text: 'Purchase ID',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        cps_order_number: {
          type: 'input',
          text: 'Order ID',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        }
      },
      tableData: {
        fields: [
          {
            key: 'select',
            label: 'Select',
            variant: 'select',
            allSelect: false
          },
          { key: 'reception_date', label: 'Reception Date' },
          {
            key: 'purchase_information',
            label: 'Purchase Information'
            // sortable: true
          },
          { key: 'image', label: 'Article Image', variant: 'w-110' },
          {
            key: 'article_information',
            label: 'Article Information',
            variant: 'mx-300'
          },
          {
            key: 'order_information',
            label: 'Order Information'
            // sortDesc: true
          },
          { key: 'qty', label: 'Delivery Quantity' },
          {
            key: 'stored_qty',
            label: 'Storage Quantity'
          },
          { key: 'warehouse', label: 'Warehouse' },
          { key: 'status', label: 'Status' },
          {
            key: 'action',
            label: ''
          }
        ],
        tabId: 'article_review',
        rowClass: '',
        class: 'new_table',
        items: [],
        status: null,
        totalItems: 9999999999999,
        pageOptions: [10, 30, 50, 100, 200]
      },
      // 搜索提交数据
      tableSubmit: null,
      initialTableSubmit: {
        status: [210, 220, 230],
        reception_date_from: null,
        reception_date_to: null,
        warehouse: null,
        purchase_id: null,
        cps_order_number: null,
        sort: null,
        limit: 100,
        page: 1
      },
      options: {
        delivery_address: []
      },
      loading: true,
      exportUrl: '',
      exportDisabled: true,
      exportErrorText: '',
      // 表格数据
      save_data: [],
      selected: [],
      selectIds: []
    };
  },

  methods: {
    itemsFn(ctx) {
      // switch (ctx.sortBy) {
      //   case 'purchase_information':
      //     if (ctx.sortDesc) {
      //       this.tableSubmit.sort = {
      //         purchase_id: 'desc'
      //       };
      //     } else {
      //       this.tableSubmit.sort = {
      //         purchase_id: 'asc'
      //       };
      //     }
      //     break;
      //   case '':
      //     this.tableSubmit.sort = null;
      //     break;
      // }

      this.CACHE.addCache(
        this.currentUser.uid,
        'ArticleReview',
        this.tableSubmit
      );

      this.loading = true;
      this.save_data = [];
      return apiBus.articleReview
        .articleReviewList(this.tableSubmit)
        .then((data) => {
          this.loading = false;
          this.tableSubmit.page = ctx.currentPage;
          this.tableSubmit.limit = ctx.perPage;
          this.tableData.items = this.searchTableData(data.data.data.list);

          this.tableData.totalItems = data.data.data.total;
          this.tableData.status = data.data.message;

          return this.tableData.items;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
          this.tableData.totalItems = 0;
          return [];
        });
    },
    searchTableData(data) {
      var tableData = [];
      data.forEach((key) => {
        let {
          id,
          reception_date,
          purchase_id,
          // order_id,
          order_date,
          qty,
          warehouse,
          status,
          product_info,
          purchase_date,
          reserve_no,
          stored_qty,
          order_id,
          cps_order_url,
          position
        } = key;
        let {
          brand,
          image_url,
          brand_article_number,
          product_name_en,
          product_name,
          color,
          color_name,
          size,
          pos_article_no
        } = product_info;
        tableData.push({
          id: id,
          select: false,
          reception_date: reception_date,
          purchase_information: {
            purchase_id: purchase_id,
            purchase_date: purchase_date,
            reserve_no: reserve_no
          },
          // order_id: order_id,
          order_information: {
            cps_order_number: order_id,
            order_date: order_date,
            cps_order_url: cps_order_url,
            position: position
          },
          image: image_url,
          qty: qty,
          warehouse: warehouse,
          status: status,
          stored_qty: stored_qty,
          article_information: {
            brand: brand,
            brand_article_number: brand_article_number,
            product_name:
              product_name_en != '' && product_name_en != null
                ? product_name_en
                : product_name,
            color: color,
            color_name: color_name,
            size: size,
            pos_article_no: pos_article_no
          },
          action: 'ArticleReview'
        });
      });
      return tableData;
    },
    searchOptions() {
      if (Object.keys(this.baseConfig).length == 0) {
        apiBus.purchase
          .getBaseConfig()
          .then((data) => {
            console.log('getBaseConfig数据缓存vuex3');
            this.$store.dispatch(RENEW_BASE_CONFIG, data.data.data);
            // 给Order Type赋值
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleOptions(data) {
      var arr = [];
      data.forEach((key, i) => {
        key.index = 1;
        if (key.value) {
          arr.push(key);
        } else {
          arr.push({
            value: 'disabled_' + i,
            label: key.label,
            index: 1,
            selectable: 'disabled'
          });
          key.children.forEach((x) => {
            x.index = 2;
            arr.push(x);
          });
        }
      });
      return arr;
    },
    renewTable() {
      this.$root.$emit('bv::refresh::table', this.tableData.tabId); // 筛选强制刷新表单
    },
    exportUrlFn() {
      // console.log();
      if (this.save_data.length > 0) {
        var ids = [];
        this.save_data.forEach((key) => {
          ids.push(this.tableData.items[key].id);
        });
        var reservationUrl = '/rest/api/purchase/download-article-review-list';
        var dataUrl = 'id=' + ids.toString();
        this.exportUrl = reservationUrl + '?' + dataUrl;
        var supplier_ids = [];
        this.save_data.forEach((key) => {
          supplier_ids.push(this.tableData.items[key].supplier_id);
        });
        Array.from(new Set(supplier_ids)).length == 1
          ? ((this.exportDisabled = false), (this.exportErrorText = ''))
          : ((this.exportDisabled = true),
            (this.exportErrorText = 'purchase supplier有多个或没有供应商'));
      } else {
        this.exportDisabled = true;
        this.exportErrorText = '';
        this.exportUrl = '';
      }
    },
    ifAllCheckbox() {
      var checkboxLength = [],
        ifCheckboxL = [];
      this.tableData.items.forEach((itme) => {
        if (itme.select) {
          checkboxLength.push(itme.select);
        }
        if (itme.selectDisabled != 'disabled') {
          ifCheckboxL.push(itme.select);
        }
      });

      if (
        checkboxLength.length == ifCheckboxL.length &&
        ifCheckboxL.length > 0
      ) {
        this.tableData.fields[0].allSelect = true;
      } else {
        this.tableData.fields[0].allSelect = false;
      }

      this.exportUrlFn();
    },
    checkboxChange(select, index, itme) {
      var splice_index = this.save_data.indexOf(index);
      console.log(itme);
      // console.log(index);
      if (select) {
        this.save_data.push(index);
        this.selected.push(itme);
        this.selectIds.push(itme.id);
      } else {
        this.save_data.splice(splice_index, 1);
        this.selected.splice(splice_index, 1);
        this.selectIds.splice(splice_index, 1);
      }
      this.ifAllCheckbox();
    },
    checkboxAll(select) {
      if (select) {
        this.tableData.items.forEach((item) => {
          item.select = true;
        });
      } else {
        this.tableData.items.forEach((item) => {
          item.select = false;
        });
      }
    },

    add0(m) {
      return m < 10 ? '0' + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      return y + '-' + this.add0(m) + '-' + this.add0(d);
    },
    init() {
      this.searchOptions();

      if (this.CACHE.ifCache('ArticleReview')) {
        console.log('读取缓存');
        this.tableSubmit = this.CACHE.getCache(
          this.currentUser.uid,
          'ArticleReview'
        );
        Object.keys(this.searchConfiguration).forEach((key) => {
          if (this.searchConfiguration[key].type == 'checked-all') {
            if (
              this.searchConfiguration[key].options != 0 &&
              this.tableSubmit[key].length ==
                this.searchConfiguration[key].options.length
            ) {
              this.searchConfiguration[key].allSelected = true;
            } else {
              this.searchConfiguration[key].allSelected = false;
            }
          }
        });

        this.$refs.TAdvancedSearch.visible =
          this.CACHE.getVisible('ArticleReview');
      } else {
        console.log('没有读取缓存');
        this.tableSubmit = this.initialTableSubmit;
      }
    }
  },

  computed: {
    ...mapState({
      baseConfig: (state) => state.baseconfig.baseconfig
    }),
    ...mapGetters(['currentUser']) // 获取用户名
  },
  mounted() {
    this.init();
    // 缓存
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId == 'advanced-search') {
        this.CACHE.addVisible('ArticleReview', isJustShown);
      }
    });

    // 高级搜索字段
    if (this.options.delivery_address.length == 0) {
      if (Object.keys(this.baseConfig).length == 0) {
        apiBus.purchase
          .getBaseConfig()
          .then((data) => {
            console.log('getBaseConfig数据缓存vuex5');
            this.$store.dispatch(RENEW_BASE_CONFIG, data.data.data);
            this.options.delivery_address =
              this.baseConfig.purchase_delivery_to;
            this.searchConfiguration.warehouse.options =
              this.options.delivery_address;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.options.delivery_address = this.baseConfig.purchase_delivery_to;
        this.searchConfiguration.warehouse.options =
          this.options.delivery_address;
      }
    } else {
      this.searchConfiguration.warehouse.options =
        this.options.delivery_address;
    }
  },
  watch: {
    'searchConfiguration.logistic.options'(n, o) {
      if (o.length == 0 && n.length > 0) {
        Object.keys(this.searchConfiguration).forEach((key) => {
          if (this.searchConfiguration[key].type == 'checked-all') {
            if (
              this.searchConfiguration[key].options != 0 &&
              this.tableSubmit[key].length ==
                this.searchConfiguration[key].options.length
            ) {
              this.searchConfiguration[key].allSelected = true;
            } else {
              this.searchConfiguration[key].allSelected = false;
            }
          }
        });
      }
    }
  }
};
</script>
<style scoped>
.filter {
  display: flex;
  align-content: center;
  justify-content: center;
}
.filter .form-group {
  margin-bottom: 0;
  width: 200px;
}
</style>
